import { Component, Vue } from "vue-property-decorator";
import { formatMoney, formatDate, getSymbolCurrency } from "@helpers";
import { BillingActions } from "@store/modules/billing/types";
import { billingModule, userModule, walletModule } from "@store/namespaces";
import { BillingRefill } from "@/types/billing";
import { Currency } from "@/types/currency";
import { UserActions, UserGetters } from "@store/modules/user/types";
import { UserWebPreferencesKey } from "@/types/user";
import debounce from "lodash.debounce";
import {
  TableHeaderItems,
  TableHeaderValue,
  TopBarFilter,
  TopBarFilterItems,
} from "@/types/components/filter-table-top-bar";
import { DataTableHeader } from "vuetify";
import { WalletGetters } from "@store/modules/wallet/types";

type TopBarTableFilter = TopBarFilter<"currency", Currency> &
  TopBarFilter<"paymentMethod", string>;

@Component
export default class RefillMixin extends Vue {
  @billingModule.Action("fetchBillingRefills")
  private readonly fetchBillingRefillsAction!: BillingActions["fetchBillingRefills"];
  @userModule.Action("fetchUserWebPreferences")
  private readonly fetchUserWebPreferencesAction!: UserActions["fetchUserWebPreferences"];
  @userModule.Action("updateUserWebPreferences")
  private readonly updateUserWebPreferencesAction!: UserActions["updateUserWebPreferences"];
  @userModule.Getter("userWebPreferences")
  private readonly userWebPreferencesGetter!: UserGetters["userWebPreferences"];
  @walletModule.Getter("walletsCurrencies")
  private readonly walletsCurrenciesGetter!: WalletGetters["walletsCurrencies"];

  private forceUpdateUserWebPreferencesKey = Date.now();
  private itemsPerPage = 15;
  private searchStr = "";
  private loadingBillingRefills = false;
  private billingRefills: Readonly<BillingRefill[]> = [];

  private topBarTableHeaderValue: Readonly<TableHeaderValue> = [];
  private topBarTableFilter: TopBarTableFilter = {
    currency: [],
    paymentMethod: [],
  };

  private get isFirstRefillForUser() {
    return !this.billingRefills.length;
  }

  private get paymentMethods() {
    return Object.values(
      this.billingRefills.reduce<
        Record<string, { text: string; value: string }>
      >((acc, item) => {
        if (!acc[item.paymentMethod]) {
          acc[item.paymentMethod] = {
            text: item.paymentMethod,
            value: item.paymentMethod,
          };
        }

        return acc;
      }, {})
    );
  }

  private get headers() {
    const headers: DataTableHeader[] = [
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.date"),
        value: "createdAt",
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.amount"),
        value: "amount",
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.currency"),
        value: "currency",
        sortable: false,
        align: "center",
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.payment_method"
        ),
        value: "paymentMethod",
        sortable: false,
        align: "center",
      },
    ];

    return headers;
  }

  private get excludeTopBarHeaders(): string[] {
    if (this.$vuetify.breakpoint.mobile) {
      return ["createdAt"];
    }

    return [];
  }

  private get topBarTableHeaderItems() {
    const items: TableHeaderItems = this.headers.filter(
      ({ value }) => !this.excludeTopBarHeaders.includes(value)
    );

    return items;
  }

  private get tableHeaders() {
    const topBarTableHeaderValue = this.topBarTableHeaderValue;

    return this.headers.filter(
      ({ value }) =>
        this.excludeTopBarHeaders.includes(value) ||
        topBarTableHeaderValue.some(
          ({ columnName, selected }) => columnName === value && selected
        )
    );
  }

  private get filterFields() {
    return [
      {
        title: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.payment_method"
        ),
        type: "checkbox",
        name: "paymentMethod",
        items: this.paymentMethods,
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.table.header.currency"),
        type: "checkbox",
        name: "currency",
        items: this.walletsCurrenciesGetter.map((value) => ({
          text: getSymbolCurrency(value),
          value,
        })),
      },
    ];
  }

  private getDownloadedData() {
    return {
      items: this.formatedRefills.map((item) => ({
        ...item,
        createdAt: this.formatDate(item.createdAt),
        amount: this.formatMoney(item.amount),
      })),
      headers: this.headers
        .filter((header) => !!header.value)
        .reduce<Record<string, string>>((acc, { value, text }) => {
          acc[value] = text;

          return acc;
        }, {}),
    };
  }

  private get filteredTransactions() {
    const { currency: filterCurrency, paymentMethod: filterPaymentMethod } =
      this.topBarTableFilter;

    return this.billingRefills.filter((item) => {
      return (
        filterCurrency.some(
          ({ value, selected }) => value === item.currency && selected
        ) &&
        filterPaymentMethod.some(
          ({ value, selected }) => value === item.paymentMethod && selected
        )
      );
    });
  }

  private get formatedRefills() {
    return this.filteredTransactions.map((item) => ({
      ...item,
      currency: this.getSymbolCurrency(item.currency),
    }));
  }

  private get items() {
    const searchStr = this.searchStr.trim().toLowerCase();

    return this.formatedRefills.filter((item) => {
      return (
        !searchStr ||
        this.formatMoney(item.amount).includes(searchStr) ||
        this.formatDate(item.createdAt).includes(searchStr) ||
        item.paymentMethod.toLowerCase().includes(searchStr) ||
        item.currency.toLowerCase().includes(searchStr)
      );
    });
  }
  private onChangeTopBarTableFilter(val: TopBarTableFilter) {
    this.topBarTableFilter = val;
  }

  private onChangeTableHeaderValue(value: TableHeaderValue) {
    this.topBarTableHeaderValue = value;
  }

  private formatMoney(value: number, options: { showSymbol?: boolean } = {}) {
    const { showSymbol = false } = options;

    return formatMoney({
      value,
      showSymbol,
    });
  }

  protected formatDate(
    val: number,
    options: { showTime?: boolean; showDate?: boolean } = {}
  ): string {
    const { showTime = true, showDate = true } = options;

    return formatDate(val, { showTime, showDate });
  }

  private getSymbolCurrency(val: Currency) {
    return getSymbolCurrency(val);
  }

  private async fetchBillingRefills() {
    if (this.loadingBillingRefills) return;

    this.loadingBillingRefills = true;

    try {
      const billingRefills = await this.fetchBillingRefillsAction();

      this.billingRefills = Object.freeze(billingRefills);
      this.loadingBillingRefills = false;
    } catch (error) {
      this.loadingBillingRefills = false;
    }
  }

  private async initWebPreferences() {
    await this.fetchUserWebPreferencesAction({
      key: UserWebPreferencesKey.REFILL,
    });

    const { value } = this.userWebPreferencesGetter({
      key: UserWebPreferencesKey.REFILL,
    });

    const userWebPreferences = value as Partial<{
      topBarTableHeaderValue: TableHeaderValue;
      topBarTableFilter: Partial<TopBarTableFilter>;
      itemsPerPage: number;
    }> | null;

    this.itemsPerPage = userWebPreferences?.itemsPerPage ?? this.itemsPerPage;
    this.topBarTableHeaderValue = this.topBarTableHeaderValue.map(
      ({ columnName, text, selected }) =>
        Object.freeze({
          columnName,
          text,
          selected:
            userWebPreferences?.topBarTableHeaderValue?.find(
              (item) => item.columnName === columnName
            )?.selected ?? selected,
        })
    );

    this.topBarTableFilter = Object.entries(
      this.topBarTableFilter
    ).reduce<TopBarTableFilter>((acc, [fieldName_, fieldValue_]) => {
      const fieldName = fieldName_ as keyof TopBarTableFilter;
      const fieldValue = fieldValue_ as TopBarFilterItems<string>;

      const fieldValueFromStorage = (
        userWebPreferences?.topBarTableFilter
          ? userWebPreferences.topBarTableFilter[fieldName]
          : undefined
      ) as TopBarFilterItems<string> | undefined;

      return {
        ...acc,
        [fieldName]: fieldValue.map(({ value, selected }) =>
          Object.freeze({
            value,
            selected:
              fieldValueFromStorage?.find((item) => item?.value === value)
                ?.selected ?? selected,
          })
        ),
      };
    }, this.topBarTableFilter);

    this.$watch(
      () => {
        return this.topBarTableFilter;
      },
      () => {
        this.forceUpdateUserWebPreferencesKey++;
      }
    );

    this.$watch(
      () => {
        return this.topBarTableHeaderValue;
      },
      () => {
        this.forceUpdateUserWebPreferencesKey++;
      }
    );

    return this.$watch(
      () => {
        return [this.itemsPerPage, this.forceUpdateUserWebPreferencesKey].join(
          "-"
        );
      },
      debounce(() => {
        this.updateUserWebPreferencesAction({
          key: UserWebPreferencesKey.REFILL,
          value: {
            topBarTableFilter: this.topBarTableFilter,
            topBarTableHeaderValue: this.topBarTableHeaderValue,
            itemsPerPage: this.itemsPerPage,
          },
        });
      }, 500)
    );
  }

  private created() {
    this.topBarTableHeaderValue = this.topBarTableHeaderItems.map(
      ({ value, text }) =>
        Object.freeze({
          columnName: value,
          text,
          selected: true,
        })
    );
  }

  private async mounted() {
    await this.fetchBillingRefills();

    this.$watch(
      () => {
        return this.walletsCurrenciesGetter.length > 0;
      },
      async (isReadyWalletsCurrencies) => {
        if (!isReadyWalletsCurrencies) return;

        this.topBarTableFilter.currency = this.walletsCurrenciesGetter.map(
          (value) =>
            Object.freeze({
              selected: true,
              value,
            })
        );

        if (!this.topBarTableFilter.paymentMethod.length) {
          this.topBarTableFilter.paymentMethod = this.paymentMethods.map(
            ({ value }) => Object.freeze({ value, selected: true })
          );
        }

        await this.initWebPreferences();
      },
      {
        immediate: true,
      }
    );
  }
}
