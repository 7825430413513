import { BillingPaymentMethod, PaymentMethodId } from "@/types/billing";
import { Currency } from "@/types/currency";

const mockPaymentMethods: BillingPaymentMethod[] = [
  {
    id: PaymentMethodId.USDT_TRANSFER,
    name: "USDT TRC20",
    comission: 0.03,
    minAmount: 200,
    maxAmount: 5000,
    availableCurrency: [Currency.USD],
    fixedComission: 2,
    currency: Currency.USD,
    externalFields: {
      TRC: "THFHz2uh9HbJdWKTggWwMNzj7ctxv94dcQ",
    },
  },
  {
    id: PaymentMethodId.CAPITALIST_USD,
    name: "Capitalist USD",
    comission: 0.05,
    minAmount: 200,
    maxAmount: 5000,
    availableCurrency: [Currency.EUR],
  },
  {
    id: PaymentMethodId.CAPITALIST_USDT_TRC20,
    name: "Capitalist USDT TRC20",
    comission: 0.05,
    minAmount: 200,
    maxAmount: 5000,
    availableCurrency: [Currency.EUR],
  },
];

export default mockPaymentMethods;
