import { Component, Vue, Prop } from "vue-property-decorator";
import { nestedFreeze } from "@helpers";
import { BillingActions } from "@store/modules/billing/types";
import { billingModule } from "@store/namespaces";
import { BillingPaymentMethod } from "@/types/billing";
import { HtmlElementId } from "@/types/element";

import PaymentMethod from "../components/PaymentMethod/PaymentMethod.vue";
import mockPaymentMethods from "@/mockData/paymentMethods";

@Component({
  components: {
    PaymentMethod,
  },
})
export default class PaymentMethodsMixin extends Vue {
  @Prop({ type: Boolean })
  private readonly isFirstRefillForUser?: boolean;

  @billingModule.Action("fetchBillingPaymentMethods")
  private readonly fetchBillingPaymentMethodsAction!: BillingActions["fetchBillingPaymentMethods"];

  private loadingPaymentMethods = false;

  private paymentMethods: Readonly<BillingPaymentMethod[]> = [];

  private get isShowedMainIntro() {
    return this.$productTour.activeIntro.type === "product-tour";
  }

  private get items() {
    if (this.isShowedMainIntro) {
      return mockPaymentMethods;
    }

    return this.paymentMethods;
  }

  private get loading() {
    return !this.isShowedMainIntro && this.loadingPaymentMethods;
  }

  private get htmlElementId() {
    return {
      paymentMethods: HtmlElementId.paymentMethods,
    };
  }

  private async fetchBillingPaymentMethods() {
    if (this.loadingPaymentMethods) return;

    this.loadingPaymentMethods = true;

    try {
      const paymentMethods = await this.fetchBillingPaymentMethodsAction();

      this.paymentMethods = nestedFreeze(paymentMethods);
      this.loadingPaymentMethods = false;
    } catch (error) {
      this.loadingPaymentMethods = false;
    }
  }

  protected mountedHook(): void {
    this.fetchBillingPaymentMethods();
  }
}
