import { Component, Vue, Prop } from "vue-property-decorator";
import { getPaymentMethodLogo } from "@helpers";
import {
  PaymentMethod as IPaymentMethod,
  PaymentMethodId,
} from "@/types/billing";
import { StepAlias } from "@/types/productTour";

@Component({
  components: {
    RefillForm: () => import("./components/RefillForm/RefillForm.vue"),
    UsdtTransfer: () => import("./components/UsdtTransfer/UsdtTransfer.vue"),
    UsdtTransferHandle: () =>
      import("./components/UsdtTransferHandle/UsdtTransferHandle.vue"),
  },
})
export default class PaymentMethod extends Vue {
  @Prop({ type: Boolean })
  private readonly isFirstRefillForUser?: boolean;
  @Prop({ type: Object, required: true })
  private readonly paymentMethod!: IPaymentMethod;

  private isShowDialog = false;

  private get isUsdtTransferMethod() {
    return this.paymentMethod.id === PaymentMethodId.USDT_TRANSFER;
  }

  private get isUsdtTransferHandleMethod() {
    return this.paymentMethod.id === PaymentMethodId.USDT_TRANSFER_HANDLE;
  }

  private get paymentMethodLogo() {
    return getPaymentMethodLogo(this.paymentMethod.id);
  }

  private get formatedComission() {
    if (!this.paymentMethod.comission) return "";

    return [
      this.$vuetify.lang.t("$vuetify.fields.commission_rate"),
      `${this.paymentMethod.comission * 100}%`,
    ].join(" ");
  }

  private toggleDialog(showed = !this.isShowDialog) {
    this.isShowDialog = showed;
  }

  private onClick() {
    this.toggleDialog(true);
  }

  private mounted() {
    if (this.paymentMethod.id === PaymentMethodId.USDT_TRANSFER) {
      this.$watch(
        () => {
          return (
            this.$productTour.activeIntro.step.alias ===
            StepAlias.PAYMENT_METHOD_USDT_TRANSFER
          );
        },
        (isShowDialog) => {
          this.isShowDialog = isShowDialog;
        },
        {
          immediate: true,
        }
      );
    }
  }
}
